<template>
  <transition-group v-if="users.length > 0" tag="div" :name="transition" class="proc-user-list grid auto-rows-fr">
    <div
      :key="stream.user.id" v-for="stream in users"
      @click="$emit('clicked-user', stream)"
      :class="containerClasses(stream)"
    >
      <vs-avatar class="flex-shrink-0" :src="stream.user.profile_picture"/>
      
      <div class="col-start-2">
        <div class="flex flex-col">
          <div class="w-48 md:w-64">
            <label class="font-bold w-full truncate">{{ stream.user.name }}</label>
          </div>
          <label
            class="truncate w-48 md:w-64"
            v-tooltip.left="{
              content: stream.user.email,
              delay: { show: 100, hide: 100 },
              classes: 'supreme-z'
            }"
          >
            {{ stream.user.email }}
          </label>
          <div v-if="stream.showAnswerIndicator">
            <label
              class="w-full right-0 font-semibold text-warning"
              v-tooltip.left="{
                content: 'Data de início',
                delay: { show: 100, hide: 100 },
                classes: 'supreme-z'
              }"
            >
              {{ stream.user.answer_created_at }}
            </label>
          </div>
        </div>
      </div>
      
      <div class="py-2 col-start-3" v-if="enabled">
        <slot :model="stream" name="append">
          <vs-dropdown
            vs-custom-content vs-trigger-click
            class="cursor-pointer float-right"
          >
            <feather-icon
              type="filled"
              icon="MoreVerticalIcon">
            </feather-icon>
            <vs-dropdown-menu>
              <vs-dropdown-item>
                <div 
                  class="flex" 
                  style="width: 150px;"
                  @click="highlight(stream)"
                >
                  <div class="w-2/12">
                    <font-awesome-icon
                      @click="highlight(stream)"
                      icon="expand" 
                      class="cursor-pointer"
                      v-tooltip.top="{
                        content: $t('destacar-usuario')
                      }"
                    />
                  </div>

                  <div class="w-10/12">
                    <div class="flex bg-gray-200 float-left">
                      {{ $t('destacar-usuario') }}
                    </div>
                  </div>
                </div>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </slot>
      </div>
    </div>
  </transition-group>
</template>

<script>
export default {
  props: {
    users: {
      type: Array,
      default: () => []
    },
    transition: {
      type: String,
      default: 'slide-fade'
    },
    enabled: {
      type: Boolean,
      default: true
    },
    itemStyling: {
      type: Function,
      default: (user) => ({
        container: 'py-2 grid gap-x-2 userlist-gridtemplate',
      })
    }
  },

  methods: {
    highlight(stream) {
      if (this.enabled) {
        this.$emit('highlight', stream)
      }
    },
    containerClasses(stream) {
      return `proc-user-list-container ${this.itemStyling(stream).container}`
    },
  },
}
</script>

<style lang="scss">
  .userlist-gridtemplate {
    grid-auto-columns: min-content minmax(min-content, 2fr) 1fr;
    grid-auto-rows: 1fr;
  }
</style>