var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.users.length > 0
    ? _c(
        "transition-group",
        {
          staticClass: "proc-user-list grid auto-rows-fr",
          attrs: { tag: "div", name: _vm.transition },
        },
        _vm._l(_vm.users, function (stream) {
          return _c(
            "div",
            {
              key: stream.user.id,
              class: _vm.containerClasses(stream),
              on: {
                click: function ($event) {
                  return _vm.$emit("clicked-user", stream)
                },
              },
            },
            [
              _c("vs-avatar", {
                staticClass: "flex-shrink-0",
                attrs: { src: stream.user.profile_picture },
              }),
              _c("div", { staticClass: "col-start-2" }, [
                _c("div", { staticClass: "flex flex-col" }, [
                  _c("div", { staticClass: "w-48 md:w-64" }, [
                    _c("label", { staticClass: "font-bold w-full truncate" }, [
                      _vm._v(_vm._s(stream.user.name)),
                    ]),
                  ]),
                  _c(
                    "label",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip.left",
                          value: {
                            content: stream.user.email,
                            delay: { show: 100, hide: 100 },
                            classes: "supreme-z",
                          },
                          expression:
                            "{\n            content: stream.user.email,\n            delay: { show: 100, hide: 100 },\n            classes: 'supreme-z'\n          }",
                          modifiers: { left: true },
                        },
                      ],
                      staticClass: "truncate w-48 md:w-64",
                    },
                    [_vm._v(" " + _vm._s(stream.user.email) + " ")]
                  ),
                  stream.showAnswerIndicator
                    ? _c("div", [
                        _c(
                          "label",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip.left",
                                value: {
                                  content: "Data de início",
                                  delay: { show: 100, hide: 100 },
                                  classes: "supreme-z",
                                },
                                expression:
                                  "{\n              content: 'Data de início',\n              delay: { show: 100, hide: 100 },\n              classes: 'supreme-z'\n            }",
                                modifiers: { left: true },
                              },
                            ],
                            staticClass:
                              "w-full right-0 font-semibold text-warning",
                          },
                          [
                            _vm._v(
                              " " + _vm._s(stream.user.answer_created_at) + " "
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                ]),
              ]),
              _vm.enabled
                ? _c(
                    "div",
                    { staticClass: "py-2 col-start-3" },
                    [
                      _vm._t(
                        "append",
                        function () {
                          return [
                            _c(
                              "vs-dropdown",
                              {
                                staticClass: "cursor-pointer float-right",
                                attrs: {
                                  "vs-custom-content": "",
                                  "vs-trigger-click": "",
                                },
                              },
                              [
                                _c("feather-icon", {
                                  attrs: {
                                    type: "filled",
                                    icon: "MoreVerticalIcon",
                                  },
                                }),
                                _c(
                                  "vs-dropdown-menu",
                                  [
                                    _c("vs-dropdown-item", [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "flex",
                                          staticStyle: { width: "150px" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.highlight(stream)
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "w-2/12" },
                                            [
                                              _c("font-awesome-icon", {
                                                directives: [
                                                  {
                                                    name: "tooltip",
                                                    rawName: "v-tooltip.top",
                                                    value: {
                                                      content:
                                                        _vm.$t(
                                                          "destacar-usuario"
                                                        ),
                                                    },
                                                    expression:
                                                      "{\n                      content: $t('destacar-usuario')\n                    }",
                                                    modifiers: { top: true },
                                                  },
                                                ],
                                                staticClass: "cursor-pointer",
                                                attrs: { icon: "expand" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.highlight(stream)
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "w-10/12" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "flex bg-gray-200 float-left",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "destacar-usuario"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                        { model: stream }
                      ),
                    ],
                    2
                  )
                : _vm._e(),
            ],
            1
          )
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }